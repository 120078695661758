import React from 'react'
import {Alert,Container} from 'reactstrap'

export default function index(){

    return (
        <Container>
            <br/>
            <Alert color="danger">
                <br/>
                <h3>Error 404 : Page not found</h3>
                <br/>
            </Alert>
        </Container>
        )

}