import React from 'react'
import {AuthConsumer} from '../../../authentication'
import ComparerTable from './ComparerTable'
import { Button, Container } from 'reactstrap'

export default function ConfrontoTerapieComponent(props){

    const Baseline = AuthConsumer._currentValue.terapie.Baseline
    const FormTrasversali = AuthConsumer._currentValue.terapie.FormTrasversali

    const getDifferences = () =>{
        const body=[]
        var elencoPrincipioAttivo = []
        if(Baseline !== null){
            Baseline.forEach((terapia)=>{
                var terObj = {}
                terObj.FormTrasversali =  null;
                terObj.Baseline = terapia;
                terObj.PrincipioAttivo = terapia.principio_attivo;
                //ricerca nel form trasversali
                if(FormTrasversali !== null){
                    FormTrasversali.forEach((terapiForm)=>{
                        if(terapiForm.principio_attivo === terapia.principio_attivo) terObj.FormTrasversali = terapiForm
                    })
                }
                elencoPrincipioAttivo.push(terObj);
            })
        }
        if(FormTrasversali !== null){
            FormTrasversali.forEach((terapiaForm)=>{
                //verifico se e' gia' presente
                var giaInserito = false
                elencoPrincipioAttivo.forEach((terapiaGiaInserita)=>{
                    if(terapiaForm.principio_attivo === terapiaGiaInserita.PrincipioAttivo) giaInserito=true
                })
                //se non e' presente la aggiungo
                if(giaInserito === false){
                    var terObj = {}
                    terObj.FormTrasversali =  terapiaForm;
                    terObj.Baseline = null;
                    terObj.PrincipioAttivo = terapiaForm.principio_attivo;
                    elencoPrincipioAttivo.push(terObj);
                }
            })
        }

        elencoPrincipioAttivo.forEach((terapia)=>{
            body.push(<ComparerTable terapia={terapia} key={terapia.PrincipioAttivo} indietro={()=>props.indietro()}/>)
        })
        
        return body
    }

    return (
        <Container>
            {getDifferences()}
            <Button onClick={()=>props.indietro()} color="danger" style={{marginLeft:"80%",width:"20%"}}>INDIETRO</Button>
            <br/>
        </Container>)
}