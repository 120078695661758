import React, { Component } from 'react';
import axios from 'axios';

import api from '../api';

const AuthContext = React.createContext();

const INITIAL_STATE = { isAuth: false, superUser: false, username: '', role: '', progetti:[],projectTriggered:0,redcapCallData:null,terapie:null,messaggioRandomizzazione:null};
 //impostare isAuth e superUser a false !! IMPORTANTE !!

class AuthProvider extends Component {
  state = INITIAL_STATE;

  login = async (username, password) => {
    const { data } = await api.authenticate(username, password);
    this.setState({ 
      isAuth: true,
      username: data.username, 
      superUser: data.flagSuperUser === 1 ? true : false,
      role: data.role,
      progetti: data.listaProgetti
    });
    axios.defaults.headers.common['Authorization'] = "Bearer " + data.jwt;
    console.log(data.jwt)
  }

  setProjectTriggered = (id) =>{
    this.setState({projectTriggered:id})
  }

  setRedcapCallData = ( data ) =>{
    this.setState({redcapCallData : data})
  }

  getImproveTerapie =async ( record ) =>{
    const { data } = await api.improveImportTerapie(record);
    
    var terapieObject = {Baseline:null,FormTrasversali:null}
    
    if(data.map.Baseline.myArrayList) terapieObject.Baseline = data.map.Baseline.myArrayList
    
    if(data.map.FormTrasversali.myArrayList) terapieObject.FormTrasversali = data.map.FormTrasversali.myArrayList

    this.setState({terapie:terapieObject})
  }

  improveCopiaTerapie =async ( record ) =>{
   await api.improveCopiaTerapie(record)
  }

  improveRandomize =async (record) =>{
    const { data } = await api.improveRandomize(record);
    this.setState( { messaggioRandomizzazione : data } );
  }
  
  logout = () => {
    this.setState(INITIAL_STATE);
    axios.defaults.headers.common['Authorization'] = undefined;
    delete axios.defaults.headers.common['Authorization'];
  }

  render() {
    return (
      <AuthContext.Provider
        value={{...this.state, 
          login: this.login, 
          logout: this.logout ,
          setProjectTriggered : this.setProjectTriggered ,
          setRedcapCallData : this.setRedcapCallData , 
          getImproveTerapie:this.getImproveTerapie,
          improveCopiaTerapie : this.improveCopiaTerapie,
          improveRandomize : this.improveRandomize
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext }