import React from 'react'
import {Alert} from 'reactstrap'
import {AuthConsumer} from '../../../authentication'

export default function Viewer(props){

    const getErrore = () =>{
        if(props.errorMessage.response.status !== 500) return  <b>{props.errorMessage.response.data}</b>
        else return  <b>Errore : il record seleziona non è stato ancora assegnato a nessun DAG.</b>
    }

    const getSuccess = () =>{
        if(AuthConsumer._currentValue.messaggioRandomizzazione){
            var splitter = AuthConsumer._currentValue.messaggioRandomizzazione.split('$');
        return  (
            <b>
                { splitter[0] }
                <br/>
                { splitter[1] }
                <br/>
                { splitter[2] }
            </b>
            )
        }
    }

    return (
    <Alert color={props.errorMessage ? "danger" : "success"}>
        {props.errorMessage ? getErrore() : getSuccess() }
        </Alert>
        )
}