import React from 'react'
import {Table,Alert,ListGroup,ListGroupItem} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt , faEquals } from '@fortawesome/free-solid-svg-icons'
import InfoTerapia from './InfoTerapia'

export default function ComparerTable(props){

    const iconDiverse = <FontAwesomeIcon icon={faSyncAlt} style={{color:'#3399ff'}}/>
    const iconUguali = <FontAwesomeIcon icon={faEquals} style={{color:'green'}}/>

    const getDifferenza = () =>{
        var frequenzaCambiata = false
        var doseCambiata = false

        if(props.terapia.Baseline!==null && props.terapia.FormTrasversali === null ) {
            doseCambiata = true
            frequenzaCambiata = true
        }
        if(props.terapia.Baseline===null && props.terapia.FormTrasversali !== null ) {
            doseCambiata = true
            frequenzaCambiata = true
        }
        if(doseCambiata === false){//confronto dose
            if(props.terapia.Baseline.dose_somministrata !== props.terapia.FormTrasversali.dose_somministrata) doseCambiata = true
            if(props.terapia.Baseline.unita_misura !== props.terapia.FormTrasversali.unita_misura) doseCambiata = true
        }
        if(frequenzaCambiata===false){//confronto frequenza
            if(props.terapia.Baseline.frequenza_number !== props.terapia.FormTrasversali.frequenza_number) frequenzaCambiata = true
            if(props.terapia.Baseline.frequenza !== props.terapia.FormTrasversali.frequenza) frequenzaCambiata = true
        }

        return(
            <ListGroup>
                <ListGroupItem>{doseCambiata?iconDiverse:iconUguali}</ListGroupItem>
                <ListGroupItem>{frequenzaCambiata?iconDiverse:iconUguali}</ListGroupItem>
            </ListGroup>
        )

    }

    return(
        <>
        <Alert color="info">{props.terapia.PrincipioAttivo}</Alert>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th key={props.terapia.PrincipioAttivo+"HB"}>
                            Baseline
                        </th>
                        <th key={props.terapia.PrincipioAttivo+"HF"}>
                            Form trasversali
                        </th>
                        <th key={props.terapia.PrincipioAttivo+"HD"}/>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th style={{width:"45%"}} key={props.terapia.PrincipioAttivo+"BB"}>
                            <InfoTerapia terapia={props.terapia.Baseline}/>
                        </th>
                        <th style={{width:"45%"}}  key={props.terapia.PrincipioAttivo+"BF"}>
                            <InfoTerapia terapia={props.terapia.FormTrasversali}/>
                        </th>
                        <th key={props.terapia.PrincipioAttivo+"BD"}>
                            {getDifferenza()}
                        </th>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}