import React from 'react'
import { ListGroup , ListGroupItem} from 'reactstrap'
import { FrequenzaCodificate } from './Constant'

export default function InfoTerapia(props){

    const getUnitaMisura = () =>{
        if(props.terapia !== null){
            var dose = props.terapia.dose_somministrata
            var unita = props.terapia.unita_misura
            return dose+" "+unita
        }else{
            return "-"
        }
    }

    const getFrequenza = () =>{
        if(props.terapia !== null){
            var ripetizioni = props.terapia.frequenza_number
            var scansioneCode = props.terapia.frequenza
            var scansioneValore = ""
            FrequenzaCodificate.forEach((coder)=>{
                if(coder.codice === parseInt(scansioneCode)) {
                    scansioneValore=coder.valore
                }
            })

            return ripetizioni+" volte "+scansioneValore
        }else{
            return "-"
        }
    }

    return (
        <ListGroup>
            <ListGroupItem>{getUnitaMisura()}</ListGroupItem>
            <ListGroupItem>{getFrequenza()}</ListGroupItem>
        </ListGroup>
    )

}
