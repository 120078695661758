import React,{useState} from 'react';
import { Button, Card, CardImg, Col, Container, Row , Alert } from 'reactstrap';
import dataAnalisy from '../../assets/data_analisy.jpg';
import CopiaTerapiePic from '../../assets/CopiaTerapiePic.png';
import ConfrontaTerapiePic from '../../assets/ConfrontaTerapiePic.png'
import Randomizzazione from '../Randomizzazione/CheckRandomizzazione'
import ConfrontoTerapieSelector from '../ImproveTool/ConfrontoTerapie/ConfrontoTerapieSelector'
import CopiaTerapie from '../ImproveTool/CopiaTerapie'
import Header from './Header'

const Improve = (props) =>{
    const [tool,setTool] = useState(null)

    const definizioneTool = () =>{
        if(props.tool) setTool(parseInt(props.tool))
        else setTool(0)
    }

    return(
        <Row style={{margin:'0'}}>
        {tool===null? definizioneTool():null}
            <Col style={{overflowX:'auto'}}>
                <Header titolo="Tool IMPROVE" />
                {props.recordName!==null?
                            <Alert color="primary">Tool per il paziente con Codice Progressivo Paziente ' <b>{props.recordName}</b> '</Alert> : null
                        }
                {tool === 0 ? 
                    <Container fluid>
                        <br/>
                        <Row style={{margin:'0'}}>
                            <Col sm={{ size: 4, offset: 1 }} >
                                <Card body >
                                    <CardImg top width="80%"  src={dataAnalisy} alt="Randomizzazione" />
                                    <Button onClick={()=>setTool(1)}>Randomizzazione</Button>
                                </Card>
                            </Col>

                            <Col sm={{ size: 4, offset: 2 }} >
                                <Card body >
                                    <CardImg top width="80%"  src={ConfrontaTerapiePic} alt="Confronto terapie" />
                                    <Button onClick={()=>setTool(2)}>Confronto Terapie</Button>
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{margin:'0'}}>
                            <Col sm={{ size: 4, offset: 1 }} >
                                <Card body >
                                    <CardImg top width="80%"  src={CopiaTerapiePic} alt="Copia terapie" />
                                    <Button onClick={()=>setTool(3)}>Copia terapie da BASELINE a FORM TRASVERSALI</Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                : null }
                {tool === 1 ? <Randomizzazione {...props} indietro={()=>setTool(0)}/>:null}
                {tool === 2 ? <ConfrontoTerapieSelector {...props} indietro={()=>setTool(0)}/> : null}
                {tool === 3 ? <CopiaTerapie {...props} indietro={()=>setTool(0)}/> : null}
            </Col>
        </Row>
       
    );
}


export default Improve;




