import axios from 'axios';

const apiConfig = {
  development: 'http://localhost:8080/',
  production: 'https://retecardioadmin-rest.cbim.it/'
};

const env = process.env.NODE_ENV || 'development';
const baseURL = apiConfig[env];
const instance = axios.create({ baseURL });

const authenticate = (email, password) => {
  const url = 'api/login';
  return instance.post(url, null ,{auth: {username: email, password }});
};

const calcolaIndiciRischio = (values) => {
  const url = 'api/indici';
  const config = {
    "headers": {
      "Authorization": axios.defaults.headers.common['Authorization']
    }
  }
  return instance.post(url, values, config );
}

const exportMds = () => {
  const url = 'api/export/mds';
  const config = {
    "headers": {
      "Authorization": axios.defaults.headers.common['Authorization']
    }
  }

  return instance.get( url, config );;
}

const exportByDag = (dag) => {
  const url = 'api/export/bydag?dag=' + dag;
  const config = {
    "headers": {
      "Authorization": axios.defaults.headers.common['Authorization']
    }
  }

  return instance.get( url, config );;
}

const importIndici = (values) => {
  const url = 'api/indici/import-indici?projectId='+values.idProgetto+"&recordId="+values.idRecord;
  const config = {
    "headers": {
      "Authorization": axios.defaults.headers.common['Authorization']
    }
  }
  return instance.post( url, values ,config );

}

const improveRandomize = (record) => {
  const url = 'api/random/randomizeImprove?idRecord='+record
  const config = {
    "headers": {
      "Authorization": axios.defaults.headers.common['Authorization']
    }
  }

  return instance.post( url, record , config );
}

const tokenImprove = "EDFEBC665309E30A12AA513A53B356B3"

const improveImportTerapie = (record) => {
  const url = 'api/improve/getTerapie?idRecord='+record;
  const config = {
    "headers": {
      "Authorization":tokenImprove
    }
  }
  return instance.get( url,config );

}

const improveCopiaTerapie = (record) => {
  const url = 'api/improve/aggiornaStorico?idRecord='+record;
  const config = {
    "headers": {
      "Authorization":tokenImprove
    }
  }
  return instance.get( url,config );

}

export default {
  authenticate,
  calcolaIndiciRischio,
  exportMds,
  exportByDag,
  importIndici,
  improveImportTerapie,
  improveCopiaTerapie,
  improveRandomize
};
