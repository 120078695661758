import React from 'react'
import {AuthConsumer} from '../../authentication'
import Randomizzazione from './Randomizzazione'
import {Container,Alert} from 'reactstrap'

export default function CheckRandomizzazione(props){

    const check = () =>{
        
        var vediRandomizzazione = false
        if(AuthConsumer._currentValue.progetti.length>0){
            const elencoProgetti = AuthConsumer._currentValue.progetti
            elencoProgetti.forEach(progetto=>{
                if(progetto.projectId === 214) vediRandomizzazione = true
            })
        }
        if(vediRandomizzazione===true){
            var datiValidi = true;
            if(props.recordName === null || props.recordName === "[record-name]") datiValidi = false
            if(props.projectId === null || props.projectId === "[project-id]") datiValidi = false

            if(datiValidi === false){
                return <Alert color="danger" > 
                Errore nella ricezione dei dati da Redcap.<br/>
                Si prega di tornare su Redcap e reinviare il modulo 
                 </Alert>
            }else{
                return <Randomizzazione
                        record={props.recordName} 
                        project={props.projectId}
                        indietro={()=>props.indietro()}
                        />

            }
        }
    }

    return (
    <Container>
        <br/>
        {check()}
        <br/>
    </Container>)

}