import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as routes from './routes';
import AuthRoute from './AuthRoute';

import LoginForm from '../components/LoginForm';
import Home from '../components/Home';
import Unauthorized from '../components/Unauthorized';
import Estrazione from '../components/Estrazione';
import Rischio from '../components/Rischio';
import BadRequest from '../components/BadRequest';
import PageNotFound from '../components/PageNotFound';

import { ALL, RISCHIO, ESTRAZIONE } from '../constants';



export const AppRoutes = () =>{

  const callData = {
    username : new URLSearchParams(window.location.search).get("un"),
    recordName : new URLSearchParams(window.location.search).get("rn"),
    projectId : new URLSearchParams(window.location.search).get("pi"),
    tool : new URLSearchParams(window.location.search).get("tl"),
    dag : new URLSearchParams(window.location.search).get("dag")
  }
  return(
    <Switch>
      <Route exact path={routes.LOGIN} component={() => <LoginForm />} />
      <Route exact path={routes.UNAUTHORIZED} component={() => <Unauthorized />} />
      <Route exact path={routes.BAD_REQUEST} component={() => <BadRequest />}/>
      <AuthRoute exact path={routes.HOME} claim={[ALL, RISCHIO, ESTRAZIONE]} component={() => <Home {...callData}/>} />
      <AuthRoute exact path={routes.EXPORT} claim={[]} component={() => <Estrazione />} /> 
      <AuthRoute exact path={routes.INDICI_RISCHIO} claim={[ALL, RISCHIO]} component={() => <Rischio />} /> 
      <Route component={()=><PageNotFound/>} />
    </Switch>
  );
}

  

export default AppRoutes;
