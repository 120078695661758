import React,{useContext,useState} from 'react'
import {AuthContext} from '../../../authentication'
import {Alert,Button} from 'reactstrap'
import Loader from '../../Loader'

export default function ConfrontoTerapieSelector(props){
    const { getImproveTerapie,terapie } = useContext(AuthContext)

    const [isLoading,setIsLoading]=useState(false)
    const [errore,setErrore]=useState(false)
    const [apiChiamata,setApiChiamata]=useState(false)

    const doGetTerapie =async () =>{
        setIsLoading(true);
        if(terapie===null){
            try{
                await getImproveTerapie(props.recordName)
                .then(function(){
                    setIsLoading(false);
                    setApiChiamata(true);
                    setErrore(false);
                    props.apiChiamata();
                })
            }catch(error){
                setIsLoading(false);
                setApiChiamata(true);
                setErrore(true);
            }
        }else{
            setIsLoading(false);
            setApiChiamata(true);
            setErrore(false);
            props.apiChiamata();
        }
    }

    return (
        <>
        {errore === true ?
            <>
                <Alert color="danger">Errore nel caricamento dei dati</Alert>
                <Button onClick={()=>props.indietro()} color="danger">indietro</Button>
            </>
            :
            isLoading === true ?
                <Loader isLoading={isLoading}/>
                :
                apiChiamata === true ?
                    null
                    :
                    doGetTerapie()
                    }
        </>
    )
}
