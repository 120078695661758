import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { Button, Card, CardImg, Col, Container, Row , Alert } from 'reactstrap';
import {INDICI_RISCHIO} from '../../navigation/routes'
import dataAnalisy from '../../assets/data_analisy.jpg';
import Header from './Header'

const Skim = (props) =>{
    const [tool,setTool] = useState(0)

    const definizioneTool = () =>{
        if(props.tool) setTool(parseInt(props.tool))
        else setTool(0)
    }

    return(
        <Row style={{margin:'0'}}>
        {tool===null? definizioneTool():null}
            <Col style={{overflowX:'auto'}}>
                <Header titolo="Tool SKIM" />
                {props.recordName!==null?
                            <Alert color="primary">Tool per il paziente con Codice Progressivo Paziente ' <b>{props.recordName}</b> '</Alert> : null
                        }
                {tool === 0 ? 
                    <Container fluid>
                        <br/>
                        <Row style={{margin:'0'}}>
                            <Col sm={{ size: 4, offset: 1 }} >
                                <Card body >
                                    <CardImg top width="80%"  src={dataAnalisy} alt="Indici di rischio" />
                                    <Link to={INDICI_RISCHIO}>
                                        <Button>Indici di Rischio</Button>
                                    </Link>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                : null }
            </Col>
        </Row>
       
    );
}


export default Skim;




