import React,{useState,useContext} from 'react'
import {Table,Button,Alert} from 'reactstrap'
import {AuthContext} from '../../authentication'
import Loader from '../Loader'
import Viewer from './Visualizzatore/Viewer'

export default function Randomizzazione(props){

    const [triggered,setTriggered] = useState(false)
    const { improveRandomize } = useContext(AuthContext);
    const [isLoading,setIsLoading] = useState(false);
    const [apiChiamata,setApiChiamata] = useState(false);
    const [errore,setErrore] = useState(false);
    const [errorMess,setErrorMess] = useState(null)

    const getRandomizeImprove = async () =>{
        try{
            await improveRandomize(props.record)
                .then(function(){
                setIsLoading(false);
                setApiChiamata(true);
                setErrore(false);
            })

        }catch(error){
            console.log(error)
            setErrorMess(error)
            setIsLoading(false);
            setApiChiamata(true);
            setErrore(true);
        }
    }

    const getRandomizeCode = () =>{
        if(!triggered){
            setIsLoading(true);
            setTriggered(true);
            if(props.project==="214") getRandomizeImprove()
        }
    }
    
    return (<div>
        {isLoading === true ?
                <Loader isLoading={isLoading}/>
                :
                errore === true ?
                      <Viewer errorMessage={errorMess} />
                      :
                      apiChiamata === true ?
                        <Viewer />
                            :
                            <>{getRandomizeCode()}</>
                            }

        {isLoading === false ? <Button color="danger" onClick={()=>props.indietro()}>INDIETRO</Button>:null}
    </div>)

}