import React,{useState} from 'react'
import Caller from './ConfrontoTerapieCaller'
import Component from './ConfrontoTerapieComponent'

export default function ConfrontoTerapieSelector(props){

    const [terapieEstratte,setTerapieEstratte] = useState(false);
    return (
        <div>
            {terapieEstratte===false ? 
                <Caller {...props} apiChiamata={()=>setTerapieEstratte(true)} indietro={()=>props.indietro()}/> 
                : 
                <Component {...props} indietro={()=>props.indietro()}/>}
        </div>
    )


}