/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

 import React from 'react';
 import PropTypes from 'prop-types';
 
 import { Col, FormFeedback, FormGroup, Alert, Label } from 'reactstrap';
 
 const RowDefaultValueInput = ({
   field: {...fields},
   form: {touched, errors},
   labelcols, 
   showuntouched, 
   label,
   record, 
   ...props
 }) =>
   <FormGroup row>
     <Label md={labelcols} for={fields.name} className="label-color">{label}</Label>
     <Col md={12 - labelcols}>
         <Alert color="dark" >{record}</Alert>
       <FormFeedback tooltip>{errors[fields.name]}</FormFeedback>
     </Col>
   </FormGroup>
 
 RowDefaultValueInput.propTypes = {
   field: PropTypes.object.isRequired, // da Formik: name
   form: PropTypes.object.isRequired,  // da Formik: touched, errors
   label: PropTypes.string.isRequired, // etichetta
   labelcols: PropTypes.number,        // num colonne per etichetta
   showuntouched: PropTypes.bool,      // mostra errore anche campo non touched (es. numerici)
   record:PropTypes.string.isRequired    
  };
 
  RowDefaultValueInput.defaultProps = { 
   labelcols: 3,
   showuntouched: false
  };
 
 export default RowDefaultValueInput;
 