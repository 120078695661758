import React from 'react';
import PropTypes from 'prop-types';

import {  FormFeedback, FormGroup, Input } from 'reactstrap';
import './formStyle.css';

const RowSelectColorInput = ({
  field: {...fields},
  form: {touched, errors},
  labelcols, 
  list,
  ...props
}) => {


  const showlist = (el, idx) => {
    return (<option value={el.color} key={el.id}>{el.desc}</option>);
  };

  return(
  <FormGroup row>
      <Input {...props} {...fields} type="select" invalid={(touched[fields.name]) && !!errors[fields.name]} >
        {list.map(showlist)}
      </Input>
      <FormFeedback tooltip>{errors[fields.name]}</FormFeedback>
  </FormGroup>
);
}


  
RowSelectColorInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  labelcols: PropTypes.number,        // num colonne per etichetta
  list: PropTypes.oneOfType([         // lista option: array di stringhe o di oggetti { id, desc }
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      desc: PropTypes.string
    }))
  ])
 };

 RowSelectColorInput.defaultProps = { 
  labelcols: 3,

 };

export default RowSelectColorInput;
