import config from './config.json'

export const _filtraProgetti = (lista, settings) => {
    const filteredList = lista.filter( project => settings.includes(project.projectId.toString()))
    const finalList = [{projectId:0, projectName:"Seleziona"}, ...filteredList];
    return renameObjectArrayProps(finalList, "projectId", "projectName")

}

export const renameObjectArrayProps = (objArr, pId, pDesc) => objArr.map(obj => ({ ...obj, id: obj[pId], desc: obj[pDesc] }));

export const tipoEstrazione = [ {id: 0, desc:"Seleziona"},
                        {id: 1, desc:"Tutti i dati"},
                        {id: 2, desc:"Minimum Dataset"} ];

export const formatoEstrazione = [ {id: 0, desc:"Seleziona"},
                        {id: 1, desc:"Excel"} ];

export const filtraProgetti = (lista) => {
    const env = process.env.NODE_ENV || 'development';
    const settings = config[env].lista_progetti.split(',')
    return _filtraProgetti(lista, settings)
}