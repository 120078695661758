/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Col, FormFeedback, FormGroup, Alert, Label } from 'reactstrap';

const getProjectName = (list,idProgetto) =>{
    var nome = ""
    list.forEach(project=>{
        if(project.projectId === parseInt(idProgetto)) nome = project.projectName
    })
    if(nome==="") return <>Errore nel caricamento delle informazioni del progetto</>
    else return <>{nome}</>
}

const RowSelectInput = ({
  field: {...fields},
  form: {touched, errors},
  labelcols, 
  label,
  list,
  idProgetto,
  ...props
}) =>
  <FormGroup row>
    <Label md={labelcols} className="label-color">{label}</Label>
    <Col md={12 - labelcols}>
      <Alert color="dark">{getProjectName(list,idProgetto)}</Alert>
      <FormFeedback tooltip>{errors[fields.name]}</FormFeedback>
    </Col>
  </FormGroup>

RowSelectInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  idProgetto:PropTypes.number,
  list: PropTypes.oneOfType([         // lista option: array di stringhe o di oggetti { id, desc }
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      desc: PropTypes.string
    }))
  ])
 };

 RowSelectInput.defaultProps = { 
  labelcols: 3,

 };

export default RowSelectInput;
