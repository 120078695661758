import React,{useContext,useState} from 'react';
import { Redirect } from 'react-router-dom';
import { BAD_REQUEST } from '../../navigation/routes';
import { AuthContext } from '../../authentication'
import Improve from './Improve'
import Skim from './Skim'
import Prevital from './Prevital'

/*
IMPROVE = 214
SKIM = 212
PREVITAL = 229
*/

const Home = (props) =>{
    const [chiamataVerificata,setChiamataVerificata] = useState(false);

    const { redcapCallData , setRedcapCallData , progetti } = useContext(AuthContext);

    const controlloDati = () =>{
        if(redcapCallData===null && props.project!==null){
            var dati = {}
            dati.projectId = props.projectId
            dati.username = props.username
            dati.record = props.recordName
            dati.dag = props.dag
            setRedcapCallData(dati)
        }
        var errorProject = false
        if(props.project==='[project-id]' || props.project===null) errorProject = true
        var errorUsername = false
        if(props.username==='[user-name]' || props.username===null) errorUsername = true
        var errorRecord = false
        if(props.recordName==='[record-name]' || props.recordName===null) errorRecord = true

        if(errorProject || errorRecord || errorUsername){
            return <Redirect to={BAD_REQUEST} />
        }else{
            setChiamataVerificata(true)
        }
    }

    return(
        chiamataVerificata === false ? 
            controlloDati()
            :
            <div>
                {props.projectId === "214" ? <Improve {...props} /> : null }
                {props.projectId === "212" ? <Skim {...props} /> : null }
                {props.projectId === "229" ? <Prevital {...props} /> : null }
            </div>
       
    );
}


export default Home;




