import React from 'react'
import styles from './Home.module.css';

export default function Header(props){


    return (
        <div className={styles.mainContainer}>
            <h4 className={styles.title}>
                {props.titolo}
            </h4>
            <h6>
                questo servizio è in comunicazione con la  &nbsp;
                <a href="https://retecardiologica.cbim.it/redcap/index.php" target="_blank" rel="noopener noreferrer"> 
                    piattaforma REDCap
                </a> &nbsp;
                della Rete Cardiologica
            </h6>
            <hr />
        </div>
    )

}