import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as Yup from 'yup';
import api from '../../api';
import { errors } from '../form';
import RischioComponent from './RischioComponent';
import { AuthContext } from '../../authentication';
import { filtraProgetti } from '../../helpers';

const { OB } = errors;

// valori iniziali del form
const getInitial = (redcapData) =>{

  const initialValues = {
    idProgetto:'0',
    idRecord: ''
  };
  
  const initialRedcap ={
    idProgetto:redcapData.projectId,
    idRecord: redcapData.record,
  }

  if(redcapData===null || redcapData === undefined){
    return initialValues
  }else{
    return initialRedcap
  }

}


// schema di validazione
const getFormSchema = (redcapData) =>{

  const formSchema = Yup.object().shape({
    idProgetto: Yup.string()
      .test(
        'section', 'Seleziona un progetto',
        val => val !== '0'
      ).required(OB),
      idRecord: Yup.string().required(OB),
  });

  if(redcapData===null || redcapData === undefined){
    return formSchema
  }else{
    return null
  }

}

const RischioContainer = () =>{ 

  const { progetti , redcapCallData } = useContext(AuthContext); 

  const setBody = (info, progetti) => {    
    const payload = {
      "idProgetto": redcapCallData.projectId,
      "idRecord": redcapCallData.record,
      "dag": progetti.filter(p => p.projectId.toString() === redcapCallData.projectId)[0].groupId
    }
    return payload;
  }

  return (    
    <Formik
      initialValues={getInitial(redcapCallData)}
      validationSchema={getFormSchema(redcapCallData)} 
      onSubmit={ async (values, actions)  => {
          
        let body = setBody(values, progetti);
          try{
            
            let response = await api.calcolaIndiciRischio(body);

            actions.setStatus({
              success: true,
              indici: response.data,
              idProgetto: values.idProgetto,
              idPaziente: values.idRecord
            })  
            //actions.setSubmitting(false)

          } catch (er) {
            actions.setStatus({
              ...er,
              success: false,
            })  
            //actions.setSubmitting(false)
          }
          
        }
      }
      render={props =>
        <RischioComponent {...props}  
        progetti={filtraProgetti(progetti)} 
        redcapCallData={redcapCallData} 
        valido={redcapCallData===null || redcapCallData === undefined ? false : true} /> 
      }
    >
    </Formik>
  )
};

RischioContainer.propTypes = {};

RischioContainer.defaultProps = {};

export default RischioContainer;