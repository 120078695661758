import React, { useContext, useState } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container } from 'reactstrap';
import LoginFormComponent from './LoginFormComponent';
import Loader from '../Loader';
import { AuthContext } from '../../authentication';
import {HOME} from '../../navigation/routes';

import { errors } from '../form';
const { OB } = errors;

const initialValues = {
  username: '',
  password: '',
};

const formSchema = Yup.object().shape({
  username: Yup.string().required(OB),
  password: Yup.string().required(OB)
});

const LoginFormContainer = ({location, history}) => {
  const {isAuth, login} = useContext(AuthContext); 
  const [isLogged, setLogged] = useState(isAuth);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  const doLogin = async ({ username, password }, { setSubmitting, setStatus }) => {

    try {
      setError();
      setLoading(true);
      await login(username, password);
      setSubmitting(false);
      setLogged(true);
    } catch (er) {
      setLoading(false);
      setLogged(false);
      setError(
        { ...er,
          messaggio: 'Accesso non eseguito. Tipo di errore: '
        });
    }
  };
  
  return (
    isLogged ? <Redirect to={HOME} /> :
    <Container>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={doLogin}
        render={props => <LoginFormComponent {...props} errore={error} />}
      />}
    </Container>
  );
};

LoginFormContainer.propTypes = {};

LoginFormContainer.defaultProps = {};

export default withRouter(LoginFormContainer);