import React, {useContext} from 'react';

import NavbarComponent from './NavbarComponent';
import { AuthContext } from '../../authentication';

const NavbarContainer = () => {
  const {isAuth, logout} = useContext(AuthContext);
  return (
        <NavbarComponent isAuth={isAuth} doLogout={logout}/> 
  );
};

NavbarContainer.propTypes = {};

NavbarContainer.defaultProps = {};

export default NavbarContainer;