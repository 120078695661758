import React from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';


import { Col, FormGroup, Label } from 'reactstrap';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);

const FileUpload = ({
  field: {...fields},
  labelcols, 
  label,
  api,
  onload,
  ...props
}) => {
    const server = {
      revert: null,
      restore: null,
      load: null,
      fetch: null,
      ...api
    };
    server.process.onload = onload;
    return (
      <FormGroup row>
        <Label md={labelcols} for={fields.name} className="label-color">{label}</Label>
        <Col md={12 - labelcols}>
          <FilePond
            //Tipi file accettati: immagini, excel, word, pdf, zip
            acceptedFileTypes= {
              ['image/*', 'application/vnd.ms-excel', 'application/msword', 'application/zip',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf',
                'application/x-zip-compressed', 'multipart/x-zip'
              ]
            }
            fileValidateTypeLabelExpectedTypes= {'Tipo file accettato: immagini, excel, word, zip, pdf'}
            maxFileSize={'5MB'}
            allowRevert={true}
            instantUpload={true}
            labelIdle={'Trascina il file o <span class="filepond--label-action"> Esplora</span> - Dimansione massima 5 MB'}
            labelFileProcessing="Caricamento"
            labelFileProcessingError="Errore nel caricamento"
            labelFileProcessingComplete="Caricamento completato"
            labelTapToCancel=""
            server={server}
            {...props} 
          />
        </Col>
      </FormGroup>
    );
  }

FileUpload.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  onload: PropTypes.func.isRequired,  // setValue
  api: PropTypes.shape({
    url: PropTypes.string,
    process: PropTypes.object
  }).isRequired    // oggetto configurazione api
 };

 FileUpload.defaultProps = { 
  labelcols: 3,
 };

export default FileUpload;
