import { FaArrowLeft } from 'react-icons/fa';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row } from 'reactstrap';
import { HOME } from '../../navigation/routes';
import { RowSelectInput, RowInput , RowDefaultValueSelecInput ,RowDefaultValueInput} from '../form';
import VisualizzaIndici from '../VisualizzaIndici'
import styles from './Rischio.module.css';


const getIsDisabled = (isSubmitting,isValid,validoRedcap) => {
  
  if(validoRedcap===true){
    if(isSubmitting) return true
    else return false
  }else{
    if(isSubmitting) return true
    else{
      if(!isValid) return true
      else return false
    }
  }
}

const RischioComponent = ({
  values,
  touched,
  errors,
  e,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
  setFieldValue,
  setFieldError,
  isSubmitting,
  isValid,
  progetti,
  redcapCallData,
  valido
}) => 
  <Container >
    <Row>
      <Col>      
        <div className={styles.mainContainer}>
          <h4 className={styles.title}>Calcolo indici di rischio</h4>
          Seleziona il progetto e inserisci il codice identificativo del record <br/>
        </div>
      </Col>
      <Col> 
        <div className={styles.buttonContainer}>
          <Link to={HOME}>
            <Button color="warning" size="sm"><FaArrowLeft />
              <span className="smallLabel">
                &nbsp;Indietro<small></small>
              </span></Button>
          </Link>
        </div> 
      </Col>
    </Row>
    <hr/>
      <Form onSubmit={handleSubmit}>
        <div>
          <div className={styles.content}>
            {!redcapCallData.projectId ? 
              <>
                <Field disabled={isSubmitting} label="Progetto" name="idProgetto" list={progetti} component={RowSelectInput} />  
                <Field disabled={isSubmitting} label="Record ID" name="idRecord" component={RowInput} />  
              </>
              :
              <>
                  <Field label="Progetto" name="idProgetto" list={progetti} idProgetto={redcapCallData.projectId} component={RowDefaultValueSelecInput}/>
                  <br/>
                  <Field label="Record ID" name="idRecord" record={redcapCallData.record} component={RowDefaultValueInput} />
              </>
            }
                       
          </div>
          <div className={styles.submit}>
            <Button color="success" type="submit" disabled={ getIsDisabled(isSubmitting,isValid,valido)}>Calcola indici<small></small></Button>
          </div>
        </div>
      </Form>

    {status && !status.success && 
      <div>
        <Alert color="danger">{status.response.data}</Alert>
        <Button color="primary" onClick={handleReset}>Calcola indici per nuovo paziente</Button>
      </div>
    }
    {status && status.success && <VisualizzaIndici indici={status.indici} idprogetto={status.idProgetto} idpaziente={status.idPaziente}/>}

    
  </Container>

RischioComponent.propTypes = {
  progetti: PropTypes.array ,
  redcapCallData : PropTypes.object,
  valido:PropTypes.bool
};

RischioComponent.defaultProps = {};

export default RischioComponent;