import PropTypes from 'prop-types';
import React,{useState} from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import {Button} from 'reactstrap';
import styles from './VisualizzaIndici.module.css'
import api from '../../api';

const VisualizzaIndici = ({
    indici, 
    idprogetto, 
    idpaziente, 
    status}) =>{
    
    const [triggered,setTriggered]=useState(false)

    const ImportMessage = () => {
        
           return(
            <div id="import-message" className="importMessage" class="p-3 mb-2 bg-primary text-white"> Gli indici sono stati importati correttamente</div>
           );
        
    };
    
    const setbody = (idprog, idpaz) => {
        const payload = {
            "idProgetto": idprog,
            "idRecord": idpaz,
            "dag": ''
        }
        
        return payload;
    }

    const importIndici = async () => {
        setTriggered(true);
        let body = setbody(idprogetto, idpaziente);
        //eslint-disable-next-line
        
        try{
            await api.importIndici(body).then(function(){
                alert('Indici importati correttamente')
            })
        }catch(error){
                setTriggered(false)
                alert("errore nell'import degli indici di rischio ")
        }
       
        //this.ListeningStateChangedEvent({showMessage: true});


    }
    
    return(
        <>
        <div>
        <MDBTable hover>
            <MDBTableHead>
                <tr>
                    <th>Indice</th>
                    <th>Valore</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
            <tr>
                    <td>Rischio cardiovascolare Progetto CUORE</td>
                    <td>{indici.cuore}</td>
                </tr><tr>
                    <td>Estimated 10-Y Risk for hard ASCVD</td>
                    <td><div>{indici.ascvd}</div></td>
                </tr>
                <tr>
                    <td>Findrisk</td>
                    <td>{indici.findrisk}</td>
                </tr>
                <tr>
                    <td>Framingham</td>
                    <td>{indici.framingham}</td>
                </tr>
                <tr>
                    <td>Framingham Diabete</td>
                    <td>{indici.framinghamDiabete}</td>
                </tr> 
                <tr className="table-secondary">
                    <td colSpan="2"><i>Framingham Ipertensione a:</i></td>
                </tr>
                <tr>
                    <td>1 anno</td>
                    <td>{indici.framinghamIpertensione1}</td>
                </tr><tr>
                    <td>2 anni</td>
                    <td>{indici.framinghamIpertensione2}</td>
                </tr><tr>
                    <td>4 anni</td>
                    <td>{indici.framinghamIpertensione4}</td>
                </tr>
                <tr class="table-secondary">
                    <td colSpan="2"><i>Euroscore</i></td>
                </tr>
                <tr>
                    <td>Con limiti di applicabilità (basso rischio)</td>
                    <td>{indici.euroscoreLowLim}</td>
                </tr>
                <tr>
                    <td>Con limiti di applicabilità (alto rischio)</td>
                    <td>{indici.euroscoreHighLim}</td>
                </tr>
                <tr>
                    <td>Limiti di applicabilità estesi (basso rischio)</td>
                    <td>{indici.euroscoreLowEst}</td>
                </tr>
                <tr>
                    <td>Limiti di applicabilità estesi (alto rischio)</td>
                    <td>{indici.euroscoreHighEst}</td>
                </tr>
            

            </MDBTableBody>
        </MDBTable>
        </div>

        <div className={styles.submit}>
            <Button color="success" disabled={triggered} onClick={() => {importIndici()}}>Importa indici in RedCap<small></small></Button>
        </div>

        
        </>
      
    );

}


VisualizzaIndici.propTypes = {
    indici: PropTypes.object
};
  
VisualizzaIndici.defaultProps = {};

export default VisualizzaIndici;