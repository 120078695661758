/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Col, FormGroup, Input, Label } from 'reactstrap';

const RowCkeckInput = ({
  field: {name, value, ...fields},
  form: {touched, errors, values},
  label, 
  ...props
}) =>
  <FormGroup check inline>
    <Col xs="12">
      <Label check for={name} className="label-check">
        <Input type="checkbox" name={name} {...props} {...fields} 
          invalid={touched[name] && !!errors[name]}
          checked={values[name]}
        />
        {label}
      </Label>
      <div className="label-check-error">{touched[name] && errors[name]}</div>
    </Col>
  </FormGroup>

RowCkeckInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name, value
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
 };

 RowCkeckInput.defaultProps = { };

export default RowCkeckInput;

//<FormFeedback tooltip>{errors[name]}</FormFeedback>