import React,{useState,useContext} from 'react'
import {Alert,Button,Container} from 'reactstrap'
import {AuthContext} from '../../../authentication'
import Loader from '../../Loader'

export default function CopiaTerapie(props){

    const { improveCopiaTerapie } = useContext(AuthContext);

    const [isLoading,setIsLoading]=useState(false)
    const [errore,setErrore]=useState(false)
    const [apiChiamata,setApiChiamata]=useState(false)
    const [copiaTerapiaTriggered,setCopiaTerapiaTriggered]=useState(false)

    const doGetTerapie =async () =>{
        setIsLoading(true);
        if(copiaTerapiaTriggered===false){
            setCopiaTerapiaTriggered(true)
            try{
                await improveCopiaTerapie(props.recordName)
                .then(function(){
                    setIsLoading(false);
                    setApiChiamata(true);
                    setErrore(false);
                })
            }catch(e){
                console.log(e)
                setIsLoading(false);
                setApiChiamata(true);
                setErrore(true);
            }
        }else{
            setIsLoading(false);
            setApiChiamata(true);
            setErrore(true);
        }
    }

    return (
        <Container>
        {errore === true ?
            <>
                <Alert color="danger">
                    Errore nella copia delle terapie dal Baseline ai Form trasversali <br/>
                    <b>Nota:La copia puo essere effettuata <u>solo</u> se non ci sono terapie nei Form trasversali</b></Alert>
                <Button onClick={()=>props.indietro()} color="danger">indietro</Button>
            </>
            :
            isLoading === true ?
                <Loader isLoading={isLoading}/>
                :
                apiChiamata === true ?
                <>
                    <Alert color="success" style={{width:"60%",marginLeft:"20%"}}>Copia delle terapie dal Baseline ai Form trasversali eseguita</Alert>
                    <Button onClick={()=>props.indietro()} color="danger">indietro</Button>
                </>
                    :
                    doGetTerapie()
                    }
        </Container>
    )
}
