import { saveAs } from 'file-saver';
import { type } from 'os';
import React, {useContext} from 'react';
import { AuthContext } from '../../authentication';
import { Button, Col, Container, Row, Card, CardImg, CardTitle, CardText, CardDeck, CardBody } from 'reactstrap';
import api from '../../api';
import styles from './Estrazione.module.css';
import research from '../../assets/research.jpg';
import mds from '../../assets/mds.jpg';
import workinprogress from '../../assets/workinprogress.png';
import { Link } from 'react-router-dom';
import { HOME } from '../../navigation/routes';
import { FaArrowLeft } from 'react-icons/fa';



const Estrazione = () =>{

    const {progetti} = useContext(AuthContext);

    const getMds = async () => {
        let response = await api.exportMds();
        console.log(response);
        var file = new File([response.data],  "mdsExport.csv", { "type": response.headers.content-type});
        saveAs(file);
    }

    const exportProjectsDag = async() => {
        
        let response = await api.exportByDag(progetti[0].groupName);

        var file = new File([response.data],  "recordByDag.csv", { "type": response.headers.content-type});
        saveAs(file);

        console.log(progetti[0].groupName);

    }

    return(
        <Container >
            <Row>
                <Col>      
                    <div className={styles.mainContainer}>
                        <h4 className={styles.title}>Estrazione Dati</h4>
                    </div>
                </Col>
                <Col>
                    <div className={styles.buttonContainer}>
                        <Link to={HOME}><Button color="warning" size="sm"><FaArrowLeft /><span className="smallLabel">&nbsp;Indietro<small></small></span></Button></Link>
                    </div> 
                </Col>
            </Row>
        
        <CardDeck>
            <Card>
                <CardImg top width="100%" src={mds} alt="MDS" />
                <CardBody>
                <CardTitle><b>Minimum Dataset</b></CardTitle>
                <CardText>Esegui l'estrazione delle variabili del Minimum Dataset per tutti i progetti della Rete</CardText>
                <Button color="success" onClick={() => { getMds() }}>Estrai file csv<small></small></Button>
                </CardBody>
            </Card>
            <Card>
                <CardImg top width="100%" src={research} alt="Card image cap" />
                <CardBody>
                <CardTitle><b>Record Istituto</b></CardTitle>
                <CardText>Esegui l'estrazione di tutti i record dell'istituto di appartenenza</CardText>
                <Button color="success" onClick={() => { exportProjectsDag() }}>Estrai record Istituto<small></small></Button>
                </CardBody>
            </Card>
            <Card>
                <CardImg top width="100%" src={workinprogress} alt="Card image cap" />
                <CardBody>
                <CardTitle><b>Spazio per futura estrazione</b></CardTitle>
                </CardBody>
            </Card>
        </CardDeck>
        </Container>
    
    );
}

Estrazione.propTypes = {};
  
Estrazione.defaultProps = {};

export default Estrazione;