
export const LOGIN = '/';
export const HOME = '/home';
export const UNAUTHORIZED = '/unauthorized';
export const BAD_REQUEST = '/bad_request'

export const EXPORT = '/estrazione';
export const INDICI_RISCHIO = '/rischio';






