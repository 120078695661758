import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import { Navbar, NavbarBrand } from 'reactstrap';
import logo from '../../assets/logo_rete_cardiologica.jpg';
import { MOBILE_SIZE } from '../../constants';
import { HOME } from '../../navigation/routes';


const mapSizesToProps = ({ width }) => ({
    isMobile: width <= MOBILE_SIZE,
  })

const NavbarComponent = ({isAuth, doLogout, history, location: { pathname }, isMobile}) => {
    
    return (
        <>
        <Navbar  dark expand="md">
            <NavbarBrand to={HOME} className="mx-auto">
                <img src={logo} alt="CBIM" width="50%" height="50%" />
            </NavbarBrand>
        </Navbar>
        </>

    );
}

NavbarComponent.propTypes = {
    isAuth: PropTypes.bool,
    doLogout: PropTypes.func.isRequired
};

NavbarComponent.defaultProps = {
    isAuth: false
};


export default withRouter(withSizes(mapSizesToProps)(NavbarComponent));