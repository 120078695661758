import React from 'react'
import {Alert,Container} from 'reactstrap'

export default function index(){

    return (
        <Container>
            <br/>
            <Alert color="danger">
                Errore nella ricezione dei dati.<br/>
                Si invita a ricaricare la sessione di <b>Redcap</b> e rilanciare il tool dal sistema <b>Redcap</b>.
            </Alert>
        </Container>
        )

}